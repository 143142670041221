export default defineComponent({
  name: 'DataTableIndeterminateCheckbox',
  props: {
    indeterminate: {
      type: Boolean,
      default: undefined,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const checkboxRef = ref<HTMLInputElement>(null!)
    watch([checkboxRef, () => props.indeterminate], () => {
      if (typeof props.indeterminate === 'boolean') {
        checkboxRef.value.indeterminate = !props.checked && props.indeterminate
      }
    })

    return () => (
      <input
        type="checkbox"
        ref={checkboxRef}
        class={
          'accent-primary text-primary focus:ring-primary h-4 w-4 max-w-4 rounded border-gray-600 bg-gray-100 ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:ring-offset-gray-800'
        }
        checked={props.checked}
        indeterminate={props.indeterminate}
        disabled={props.disabled}
      />
    )
  },
})
